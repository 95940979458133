import { useMonarchSlot } from '@redventures/cohesion-utils-react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ZIPLY_UNSERVE } from '~/constants/address';
import { DEFAULT_API_VERSION, DEFAULT_ERROR_MESSAGE } from '~/constants/config/common';
import { PAGE_ROUTES } from '~/constants/pages';
import isNurtureCampaign, { nurtureRedirect } from '~/helpers/checkNurtureCampaign';
import { FuelContext } from '~/providers/FuelProvider';
import { setServiceabilityResults, setUnservReason } from '~/redux/actions/address';
import { setPageLoading } from '~/redux/actions/common';
import { setNotifications } from '~/redux/actions/global';
import { Address, ServiceabilityResponse } from '~/types/address';
import { CartConfigEditableLoader } from '~/types/editable-loader';
import { ReduxState } from '~/types/redux';
import { cartConfigEditableLoaderDefaultValue } from '../../monarch/Config | Editable Loader/default';
import useFetch from './useFetch';

interface ServiceType {
  address?: Address;
  addressKey?: string;
  env?: string;
  controlNumber?: string;
}

interface ReturnType {
  runServiceability: (payload: ServiceType) => Promise<ServiceabilityResponse>;
  data: ServiceabilityResponse | null;
  loading: boolean;
}

export const useRunServiceability = (): ReturnType => {
  const dispatch = useDispatch();
  const { router } = useContext(FuelContext);
  const { unservReason, monarch } = useSelector((store: ReduxState) => store);
  const apiVersion = (monarch?.apiVersion as string) || DEFAULT_API_VERSION;

  const { data, loading, fetch } = useFetch<ServiceabilityResponse>(`${apiVersion}/serviceability`, { method: 'post' });
  const loaderMetadata = useMonarchSlot<CartConfigEditableLoader>('cartConfigEditableLoader', {
    default: cartConfigEditableLoaderDefaultValue,
  })?.data;

  const runServiceability = async ({ address, addressKey, env, controlNumber }: ServiceType) => {
    if (unservReason) {
      dispatch(setUnservReason(''));
    }

    if (address?.state && address.state in ZIPLY_UNSERVE) {
      dispatch(setUnservReason(`address is in ${Object.keys(ZIPLY_UNSERVE)}`));
      isNurtureCampaign(router.query) ? nurtureRedirect() : router.push(PAGE_ROUTES.UNSERVICEABLE_ZIPLY);
    } else {
      let body = {};
      if (address) {
        body = {
          addressKey: address.addressKey,
          address: {
            address: address.address,
            address2: address.address2,
            city: address.city,
            state: address.state,
            postalCode: address.zip,
          },
        };
      } else if (env && controlNumber) {
        body = {
          addressKey,
          e: env,
          c: controlNumber,
        };
      }

      try {
        const serviceabilityResult = await fetch({ body: JSON.stringify(body) });
        dispatch(
          setServiceabilityResults({
            response: serviceabilityResult,
            router,
            apiVersion,
            loaderMetadata,
          })
        );
        return serviceabilityResult;
      } catch (error) {
        dispatch(setPageLoading(false));
        dispatch(setNotifications([{ type: 'error', message: DEFAULT_ERROR_MESSAGE }]));
        return { success: false, error: DEFAULT_ERROR_MESSAGE };
      }
    }
  };

  return { runServiceability, data, loading };
};

import { FormEvent, useContext, useEffect, useState } from 'react';
import { PAGE_ROUTES } from '~/constants/pages';
import { FuelContext } from '~/providers/FuelProvider';
import { Address } from '~/types/address';
import { useDispatch, useSelector } from 'react-redux';
import { resetAddressRoute, setAddress } from '~/redux/actions/address';
import { ReduxState } from '~/types/redux';
import { useRunServiceability } from '~/hooks/useRunServiceability';
import {
  removeACP,
  setPageLoading,
  setResume,
  setCookiePass,
  setBuyflowRedirectDelay,
} from '~/redux/actions/common';
import { setNewRelicPageAction, setTrackingCustomAttributes } from '~/helpers/newrelic';
import { DEFAULT_API_VERSION } from '~/constants/config/common';
import { setNotifications } from '~/redux/actions/global';
import Cookie from 'js-cookie';

interface ReturnType {
  apiVersion: string;
  selectedAddress: Address;
  installAddress: Address | Record<string, unknown>;
  setSelectedAddress: (address: Address) => void;
  handleFormSubmit: (event: FormEvent<HTMLFormElement>, onComplete?: () => Promise<void>) => Promise<void>;
  handleInputChange: (event: FormEvent<HTMLInputElement>) => void;
  buttonState: {
    disabled: boolean;
    loading: boolean;
  };
}

const useAddressCheck = (): ReturnType => {
  const { address, tracking, acp, monarch, landingPage } = useSelector((store: ReduxState) => store);
  const [selectedAddress, setSelectedAddress] = useState<Address>({
    addressKey: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    parsedAddress: {},
  });
  const { router, trackEvent } = useContext(FuelContext);
  const dispatch = useDispatch();
  const { runServiceability, loading: serviceabilityLoading } = useRunServiceability();

  setTrackingCustomAttributes(tracking);

  const apiVersion = (monarch?.apiVersion as string) || DEFAULT_API_VERSION;

  //set zip cookie for preamp
  Cookie.set('zip', address?.install?.zip ?? 'none');

  useEffect(() => {
    if (landingPage?.persisted === false) {
      trackEvent({
        action: 'elementViewed',
        data: {
          elementType: 'CART STATE',
          location: 'address_check_page',
          position: 'PERSISTED FROM MLP',
          text: 'DIFF ADDRESS',
        },
      });
    }
  }, [landingPage?.persisted]);

  // ACP or Cookie pass flow.
  useEffect(() => {
    let timeoutId = null;

    if (monarch.apiVersion && !monarch?.enabled) {
      const acpFlow = acp?.env && acp?.controlNumber;
      const cookiePassFlow = address?.install && Cookie.get('frontierSiteDetailPredictive');

      // We will need to wait on Monarch to respond so we need to throw up a loader for slow requests.
      if (acpFlow || cookiePassFlow) {
        dispatch(setResume('resumeEnabled', false));
        dispatch(setResume('acceptedResume', false));
        dispatch(setPageLoading(true));
        timeoutId = setTimeout(() => {
          dispatch(setPageLoading(false));
        }, 5000);
      } else {
        dispatch(setCookiePass(false));
      }

      if (acpFlow) {
        clearTimeout(timeoutId);
        runServiceability({ ...acp });
        Cookie.remove('frontierSiteDetailPredictive', {
          path: '/',
          domain: window?.location?.hostname === 'localhost' ? 'localhost' : '.frontier.com',
        });
        dispatch(removeACP());
      } else if (cookiePassFlow) {
        clearTimeout(timeoutId);
        runServiceability({ address: address?.install });
        Cookie.remove('frontierSiteDetailPredictive', {
          path: '/',
          domain: window?.location?.hostname === 'localhost' ? 'localhost' : '.frontier.com',
        });
        dispatch(removeACP());
      }
    }

    // If the component unmounts, clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [monarch.apiVersion, monarch.determineResumeQuote]);

  // Update address if updated in the redux state.
  useEffect(() => {
    if (!serviceabilityLoading && address?.install?.address) {
      setSelectedAddress(address?.install);
    }
  }, [address?.install?.address]);

  // Prefetch the next page chunk.
  useEffect(() => {
    if (router.route !== PAGE_ROUTES.CUSTOMIZE) {
      router.prefetch(PAGE_ROUTES.PLAN_PACKAGE);
    }
  }, [router.route]);

  // Setting the buyflow redirect delay. Doing this as separate state outside of Monarch
  // so it doesn't get overridden on a page change and the value is ready immediately on pageload
  // on the Confirmation page.
  useEffect(() => {
    if (typeof monarch?.buyflowRedirectDelay === 'number') {
      dispatch(setBuyflowRedirectDelay(monarch.buyflowRedirectDelay));
    }
  }, [monarch?.buyflowRedirectDelay]);

  // Event Handlers
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>, onComplete?: () => Promise<void>) => {
    event.preventDefault();
    dispatch(resetAddressRoute());
    // Hides error banner when running new address
    dispatch(setNotifications());

    trackEvent({
      action: 'elementClicked',
      data: {
        text: 'Check availability',
      },
      event,
    });
    setNewRelicPageAction('addressFormSubmitClicked');

    dispatch(setAddress('install', selectedAddress));

    const serviceabilityResponse = await runServiceability({ address: selectedAddress });
    if (!serviceabilityResponse || serviceabilityResponse.success === false) return;

    if (onComplete) {
      await onComplete()
    }
  };

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    setSelectedAddress({
      ...selectedAddress,
      [name]: value,
    });
  };

  return {
    apiVersion,
    selectedAddress,
    installAddress: address?.install || {},
    setSelectedAddress,
    handleFormSubmit,
    handleInputChange,
    buttonState: {
      disabled: !selectedAddress.addressKey,
      loading: serviceabilityLoading,
    },
  };
};

export default useAddressCheck;
